import React from 'react'
import republika from '../images/republikashqiperis.png'
import NjoftimeBread from '../components/njoftimenav'

const Provime: React.FC = () => {
  return (
    <>
      <NjoftimeBread />
      <ProvimeInfo />
    </>
  )
}

const ProvimeInfo: React.FC = () => {
  return (
    <div className="text-container">
      <div className="main-cont">
        <img
          src={republika}
          alt="Republika e Shqiperise"
          className="logo-container"
        />
        <h4>UDHEZIM</h4>
        <h4>Nr.5,data 17.02.2023</h4>
        <h4>
          ZHVILLIMIN E PROVIMEVE KOMBÉTARE TÉ ARSIMIT BAZÉ, NÉ REPUBLIKEN E
          SHQIPÉRISÉ, VITI SHKOLLOR 2022-2023 (SESIONI 1 PARE)
          <p>
            Ne mbeshtetje te nenit 102 te Kushtetutes Sé Republikes se
            Shqiperise dhe te neneve 26 dhe 50, te ligiit nr. 69/2012, "Pér
            sistemin arsimor parauniversitar né Republiken e Shqipérisé", té
            ndryshuar,
          </p>
        </h4>
        <h4>Udhezoj</h4>
      </div>

      <ol type="1">
        <li>
          Provimet kombétare té arsimit bazé Pér vitin shkollor 2022-2023
          (sesioni i paré) do té zhvillohen ne datat si me poshté:
        </li>
        <ol type="a">
          <li>Lénda "Gjuhé shqipe", 20 qershor 2023.</li>
          <li>Lénda "Matematiké", mé 23 qershor 2023.</li>
          <li>Lénda "Gjuhé e huaj" e paré, mé 26 qershor 2023.</li>
        </ol>
        <li>
          Nxénésit e pakicave kombétare zhvillojné edhe provimin kombétar té
          arsimit bazé né léndén "Gjuhé amtare". Provimi kombétar i arsimit bazé
          ne léndén "Gjuhé amtare", do té zhvillohet mé daté 21 qershor 2023.
        </li>
        <li>
          Procedurat e hartimit dhe vlerésimit té testeve pércaktohen nö
          rregulloren Pér zhvillimin e provimeve kombétare té arsimit bazé né
          Republikén e Shqipérisé, miratuar me Urdhrin nr. 72, daté 25.02.2020,
          té Ministrit tö Arsimit, Sportit dhe Rinisé, "Pér miratimin e
          rregullores Pér zhvillimin e provimeve kombétare tö arsimit bazé, nö
          Republiken e Shqipérise .
        </li>
        <li>
          Gjuhét e huaja qé do té jepen si provim i detyruar, jané: "Anglisht",
          "Fréngjisht", "Italisht" dhe "Gjermanisht". Testi i gjuhés Sé huaj té
          paré hartohet ne pérputhje me gjuhén e cila, ne planin mésimor té
          arsimit té mesém té ulét, trajtohet si gjuhé e huaj e detyruar né
          nivelin giuhésor A2.
        </li>
        <li>
          Nxénésit e klasave te 9-ta te shkollave mbéshtetése té seksioneve
          dygjuhéshe franceze, italiane dhe gjermane japin provim gjuhén
          perkatése te seksionit ne nivelin gjuhésor
        </li>
        <li>
          Cdo provim kombétar i arsimit bazé fillon né orén 10:00 dhe zgjat 2
          oré e 30 minuta.
        </li>
        <li>
          Pér hartimin e testit né Iéndén "Gjuhé amtare" te pakicés kombétare
          greke, ndiqet procedura si vijon:
        </li>
        <ol type="a">
          <li>
            Testi, pérgatitet nga njé grup pune me 3 (tre) mésues dhe 1 (njé)
            pedagog, té ngritur me urdhér té drejtorit te Drejtorisé Rajonale te
            Arsimit Parauniversitar (DRAP) Fier
          </li>
          <li>
            Mésuesit dhe pedagogu qé hartojné testin propozohen nga zyrat
            vendore te arsimit parauniversitar pérkatésisht: Zyra Vendore e
            Arsimit Parauniversitar (ZVAP) FiniqDropull, ZVAP
            Gjirokastér-Lib0hové, ZVAP Sarandé-Konisp01-Delviné dhe ZVAP
            Vloré-Himaré.
          </li>
          <li>
            Grupi i punés qé pérgatit testin mblidhet ne ZVAP Finiq-Dropull.
          </li>
          <li>Testi térhiqet ne ZVAP Finiq-Dropull.</li>
          <li>
            Pérfaqésuesit e ZVAP Gjirokastér-Libohové, ZVAP
            Sarandé-Konispol-Delviné dhe ZVAP Vloré-Himaré e térheqin testin ne
            ZVAP Finiq-Dropull, njé dité para provimit, ndérsa ata té ZVAP
            Finiq-Dropull e térheqin testit né ditén e provimit.
          </li>
        </ol>
        <li>
          Pér hartimin e testit né léndén "Gjuhe amtare" te pakices kombetare
          maqedonase, ndiqet procedura si vijon:
        </li>
        <ol type="a">
          <li>
            Testi, pérgatitet nga njé grup pune me 3 (tre) mésues dhe 1 (njé)
            pedagog, te ngritur me urdhér té drejtorit te DRAP-sé Korcr.
          </li>
          <li>Grupi i punés qé pérgatit testin mblidhet ne DRAP Korcé.</li>
          <li>Testi térhiqet ne DRAP Korcé.</li>
          <li>
            Pérfaqésuesit e ZVAP Korcé-Pustec e térheqin testin ne ditén e
            provimit.
          </li>
        </ol>
        <li>
          Nxénésit me aftési te kufizuara qé ndjekin shkollat e zakonshme, i
          zhvillojné provimet kombétare te arsimit bazé, mbéshtetur ne planin
          edukativ individual me te cilin punojné ne pérshtatje me veqorité e
          tyre.
        </li>
        <li>
          Drejtuesi i institutit té nxénésve nuk shikojné "Ramazan Kabashi", e
          térheq testin ne Qendrén e Shérbimeve Arsimore (QShA), njé dité para
          zhvillimit te provimit dhe e kthen até ne sistemin e shkrimit Brail.
        </li>
        <li>
          Sektori i Kurrikulés dhe Zhvillimit Profesional né ZVAP-né pérkatése,
          merr nga qendrat e vlerésimit te testeve dhe ruan té dhénat e Gdo
          nxénési, te vlerésuesve te testeve dhe té mjediseve ku jané realizuar
          provimet kombétare te arsimit bazé. Kéto té dhéna dérgohen népérmjet
          DRAP-sé ne QShA dhe ne Drejtoriné e Pérgjithshme té Arsimit
          Parauniversitar.
        </li>
        <li>
          Ngarkohen Pér zbatimin e kétij Udhézimi, Sekretari i Pérgiithshém,
          Drejtoria e Pérgjithshme e Zhvillimit Pér Arsimin dhe Sportin, Qendra
          e Shérbimeve Arsimore, Agiencia për Sigurimin e Cilësisë së Arsimit
          Parauniversitar, Drejtoria e Përgjithshme e Arsimit Parauniversitar,
          drejtoritë rajonale të arsimit parauniversitar, zyrat vendore të
          arsimit parauniversitar dhe institucionet arsimore parauniversitare.
          Ky Udhëzim hyn në fuqi pas botimit në Fletoren Zyrtare.
        </li>
      </ol>
      <h3>Minister : EVIS KUSHI</h3>
    </div>
  )
}

export default Provime
