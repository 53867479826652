import React from 'react'
import StrukturaBread from '../components/strukturanav'

const Stafi: React.FC = () => {
  return (
    <>
      <StrukturaBread />
      <h3 className="mt-16">Duke u punuar .....</h3>
    </>
  )
}

export default Stafi
