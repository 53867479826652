import React from 'react'
import '../App.css'
import NjoftimeBread from '../components/njoftimenav'
import kartaPer from '../images/kartaperformanca.png'

const KartaPerformanca: React.FC = () => {
  return (
    <>
      <NjoftimeBread />

      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-10">
        <img src={kartaPer} alt="" />
      </div>
    </>
  )
}

export default KartaPerformanca
