import React from 'react'
import AboutNav from '../../components/aboutnav'
import republika from '../../images/republikashqiperis.png'

const Rregullorja: React.FC = () => {
  return (
    <>
      <AboutNav />
      <RregulloreList />
    </>
  )
}

const RregulloreList: React.FC = () => {
  return (
    <div className="text-container">
      <div className="main-cont">
        <img src={republika} alt="" />
        <h4>DREJTORIA E PERGJITHSHME PARAUNIVERSITAR</h4>
        <h4>DREJTORIA RAJONALE E ARSIMIT PARAUNIVERSITAR LEZHË</h4>
        <h4>ZYRA VENDORE ARSIMORE SHKODËR</h4>
        <h4>SHKOLLA 9-VJECARE “NDRE MJEDA” SHKODËR</h4>
        <h4>RREGULLORE E BRENDSHME E SHKOLLËS</h4>
        <h4>VITI SHKOLLOR 2022 - 2023</h4>
      </div>

      <ol type="1">
        <li>
          Shkolla është një institucion laik, e depolitizuar dhe qellimi I vetem
          i saj eshte arsimimi I nxënësve dhe edukimi I tyre.
        </li>
        <li>
          Cdo të hënë para fillimit të mësimit këndohet “Himni I Flamurit” para
          shkolle.
        </li>
        <li>
          Mësimi fillon në orën 8.00 - 13.00 ( turni I ) , 13.00-17.00 ( turni
          II ) .
        </li>
        <li>
          Orari për hyrjen e nxënësve në shkollë eshte 7.40 I rregulluar sipas
          një grafiku që hartoi drejtoria e shkollës dhe nën monitorimin e
          mësuesve të kujdestarisë ditore/javore.
        </li>
        <li>
          Ora e mësimit nuk ndërpritet nga drejtuesit apo mësuesit e tjerë dhe
          nuk lejohet asnjë mësues apo punonjës të institucioneve të tjera ose
          vizitor në orën e mësimit, përvec, rasteve të emergjencës.{' '}
        </li>
        <li>
          Nxënësit hyjnë në shkollë pasi janë vendosur në radhë një nga një, me
          largësi 2 metra.
        </li>
        <li>
          Mesuesi nuk e nderpret procesin mesimor per te komunikuar me nje
          person tjeter.
        </li>
        <li>
          Mesuesit ndihmes ose te tjeret, qe bashkepunojne me mesuesin, lejohen
          ne oren e mesimit vetem nga drejtori i shkolles
        </li>
        <li>
          Dera e shkollës do të qëndrojë e mbyllur, ndërsa dritaret e klasave ku
          zhvillohet procesi mësimor, do të qëndrojnë të hapura për të krijuar
          ajrosjen e nevojshme brenda tyre.
        </li>
        <li>Lejohet vetëm një hyrje dhe një dalje në ndërtesën e shkollës.</li>
        <li>
          Dalja nga ambientet e shkollës do të bëhet sipas rregullave nën
          mbikëqyrjen e mësuesve kujdestarë dhe të punonjësve të tjerë të
          shkollës.{' '}
        </li>
      </ol>
      <h5>Nxenesi ka te drejte:</h5>
      <ol type="1">
        <li>
          Te kryeje veprimtarite e tij ne shkolle ne kushtet e qeta dhe te
          sigurta per jeten dhe shendetin.
        </li>
        <li>
          Te trajtohet me respekt, me dinjitet ne menyre te kulturuar dhe te
          moralshme ne shkolle, pa presione, pa padrejtesira, pa fyerje, pa
          diskriminime, pa dhune.
        </li>
        <li>Te njihet me te drejtat dhe detyrat e tij ne shkolle.</li>
        <li>
          T’i sigurohet nga shkolla sherbim arsimor cilesor, sipas interesave,
          nevojave dhe mundesive te tij, si dhe ndihme e posacme per te
          perballuar veshtiresite e tij te vecantat e te nxenit.{' '}
        </li>
      </ol>
      <h5>Nxenesi ka per detyre:</h5>
      <ol type="1">
        <li>
          Nxenesit duhet te paraqiten ne shkolle me veshje te pershtatshme. Ora
          e ed.fizik duhet te zhvillohet me veshje sportive{' '}
        </li>
        <li>
          Te paraqiten në shkollë duke respektuar me përpikmëri orarin e
          komunikuar nga mësuesi kujdestar i tyre.
        </li>
        <li>
          Te respektoje mesuesit dhe shoket e klases e te shkolles, dhe te
          kerkoje respektimin e tyre ne dhe nga nxenesit e tjere dhe mesuesit
        </li>
        <li>
          Te respektoje rregullat e institucionit per mbrojten e shendetit, te
          sigurise e te mjedisit
        </li>
        <li>
          Te vije ne shkolle I pajisur me tekste,fletore e mjete te tjerat e
          domosdoshme.
        </li>
        <li>
          Te mbaje qendrim te mire dhe te mos behet pengese per zhvillimin
          normal te ores se mesimit
        </li>
        <li>Nxenesi ndalohet te pertypet gjate ores se mesimit</li>
        <li>Ndalohet te hyje ne klase pasi ka rene zilja.</li>
        <li>Ndalohet te pije duhan, te mbaje arme zjarri apo te ftohta. </li>
        <li>Nuk lejohet celulari ne shkolle, qofte edhe i fikur. </li>
        <li>
          Nese nje nxenes largohet nga mesimi ai duhet te sjelle arsyen me
          shkrim te firmosur nga prindi. Per mungesa ma te gjata se tre dite per
          arsye shendetsore ai duhet te kete raportin mjeksore. Per mungesa
          shume te gjata, prindi duhet te njoftoje drejtorine e shkolles
        </li>
        <li>
          Duhet te shmanget ndotja e oborrit te shkolles, ambjenteve perreth,
          nderteses, si dhe klasave.
        </li>
        <li>Ndalohet te marre pjese ne lojrat me karakter bixhozi</li>
        <li>
          Ndalohet te flase me ze te late ne mjediset ku zhvillohet mesimi
        </li>
        <li>
          Te paguaje kontributin prej 1000 leke ne vit ne menyre vullnetare per
          nevojat e shkolles.
        </li>
        <li>
          Qeveria e nxenseve eshte organizem qe mbron dhe promovon te drejtat e
          nxenseve dhe ndihmon ne mbarevajtjen e shkolles
        </li>
        <li>
          Kryetari I qeverise se nxenesve zgjidhet nga nxenesit me vote te
          drejtperdrejte dhe te fshehte.{' '}
        </li>
        <li>
          Nxenesit jane pergjegjes per demtimet e shkaktuara. Ne keto raste
          prindi duhet te pergjigjet per te gjitha shpenzimet.{' '}
        </li>
        <li>
          Nxënësit e ciklit të ulët 9 vjeçar duhet te mbajnë maskë gjatë orëve
          të mësimit në ambjentet të shkollës.
        </li>
        <li>
          Nxënësit e ciklit të lartë 9 vjeçar mbajnë maskë në ambjentet e
          brendshme dhe në të gjitha aktivitetet mësimore në ambjentet e
          shkollës.
        </li>
        <li>
          Mësuesit sigurohen që në klasë të gjithë të jenë të pajisur me maskë
          dhe t’i mbajnë rregullisht ato.
        </li>
        <li>
          Nxënësit ndalohen të dalin nga klasa gjatë orëve të mësimit përveç
          rasteve kur i duhet të përdorin tualetet. Në këtë rast nxënësi duhet
          të shoqërohet.
        </li>
        <li>
          Nxenesit nuk duhet te paraqiten ne shkolle me sende me vlere te
          vecante.{' '}
        </li>
        <li>
          Keshilli I mesuesve te institucionit arsimor, I cili ka ne perberje te
          gjithe mesuesit dhe kryesohet nga drejtori, eshte organ kolegjal
          keshillimor per drejtimin e veprimtarise se insititucionit.
        </li>
        <li>
          Detyrat dhe funksionet e keshillit te mesuesve percaktohen me udhezim
          te ministrit.{' '}
        </li>
        <li>
          Mesuesi paraqitet ne shkolle 15 minuta para fillimit te orarit mesimor
          te shkolles
        </li>
        <li>
          Mesuesi mund te paraqitet ne pune me vonese ose te largohet gjate
          kohes se punes vetem me lejen e drejtorit te shkolles ose prej
          personit te ngarkuar prej drejtorit.{' '}
        </li>
      </ol>
      <h5>Mesuesi ka per detyre :</h5>
      <ol type="1">
        <li>Te respektoje Kodin e Etikes se mesuesit</li>
        <li>Te zbatoje aktet ligjore e nenligjore, qe jane ne fuqi; </li>
        <li>Te perkujdeset per mbarevajtjen e cdo nxenesite tij;</li>
        <li>Te zbatoje dhe te zhvilloje kurrikulen</li>
        <li>Te zbatoje rregulloren e shkolles, te cilen vete e miraton;</li>
        <li>Te jape ndihmesen e tij per mbarevajtjen e procesit mesimor. </li>
        <li>Te mbaje uniformen shkollore.</li>
        <li>
          Te hartoje dhe te dorezoje ne afatet e caktuara kopjen origjinale te
          planeve mesimore vjetore me % e oreve mesimore dhe te punes edukative
          ne drejtorine e shkolles.
        </li>
        <li>
          Mesuesi e ka te ndaluar qe per oret e programit te parashikuara per
          perpunim njohurish, te zhvilloje te ore ne programin lendor
          parashikohen per njohurite reja.{' '}
        </li>
        <li>
          Mesuesi harton planin (ditarin) e cdo ore mesimore dhe e ruan ate per
          nje vit shkollor.
        </li>
        <li>
          Mesuesi kujdestar I nje klase eshte nje nga mesuesit lendor te klases
          dhe caktohet nga drejtori I shkolles.{' '}
        </li>
        <li>
          Mesuesi kujdestar e ka te ndaluar qe ne mbledhjet me prinderit te
          permende me emer nxenes te klases per arritjet ose per mosarritjet e
          tyre. Informacioni per nxenesin I jepet vetem prinderve te tij.{' '}
        </li>
        <li>Ben dezhurnin sipas grafikut te hartuar nga drejtoria.</li>
        <li>Mesuesi dezhurn, ne pushimin e madh, duhet te jete ne oborr. </li>
        <li>Dezhurni eshte javor. </li>
        <li>
          Ben te gjitha perpjeket per te realizuar oret e humbura, pavaresisht
          nga arsyet e humbjes.
        </li>
        <li>Mësuesi paraqitet në shkollë në orën 07.45. </li>
      </ol>
      <h5>Mesuesit nuk i lejohet</h5>
      <ol type="1">
        <li>
          Te ushtroje dhune fizike ose psikologjike ndaj nxenesve ose kolegeve
        </li>
        <li>Te largoje nxenesin nga shkolla per ceshtje a nevoja vetjake</li>
        <li>Te kete marredhenie me para me nxenesit ose prinderit e tyre</li>
        <li>
          T’I detyrojne nxensit te blejne literature qe nuk pershihen ne
          katalogun vjetor te teksteve shkollore.
        </li>
        <li>T’u deklaroje nxenesve bindjet e tij partiake ose fetare. </li>
        <li>Te marre pjese ne grupe pune qe kane me te konflikt interesi</li>
        <li>Nuk I lejohet te perdore shprehje ofenduese per nxenesit.</li>
        <li>
          Mësuesi mban gjatë orarit zyrtar maskën dhe solucione dezinfektuese
        </li>
        <li>
          Gjatë orës së mësimit, mësuesi qëndron në klasë duke respektuar
          distancimin social dhe fizik me nxënësit.
        </li>
        <li>
          Mësuesi largohet nga institucioni vetëm me miratim të drejtorit të
          shkollës.
        </li>
        <li>
          Shoqeron nxenesit gjate hyrjes dhe daljes ne klase ne mengjes shoqeron
          klasen qe ka ne kujdestari, ne pushimin e madh shoqeron nxenesit me te
          cilet ka mesim ate ore. (Mesuesi rreshtohet bashke me nxenesit){' '}
        </li>
        <li>
          Nuk i lejohet te largohet nga shkolla para orarit te percaktuar nga
          kontrata kolektive e punes. Me sakte mesuesi duhet te qendroje ne
          shkolle jo me pak se 30 ore ne jave.{' '}
        </li>
        <li>
          Mesuesi nuk I lejohet te mbaje ndezur celularin gjate ores se mesimit.{' '}
        </li>
        <li>Nuk I lejohet te dale nga ora e mesimit per asnje arsye. </li>
        <li>
          Nuk I lejohet te beje mesim me nxenesit e tij jashte orarit te
          mesimit.
        </li>
        <li>Nuk ka te drejte te nxjerre nxenesin jashte ores se mesimit. </li>
        <li>
          Nuk I lejohet te beje propaganda partiake e politike ose fetare me
          nxenesit ne mjediset e shkolles.
        </li>
        <li>
          Nuk ka te drejte leje me te drejte rroge, pervec rasteve te
          jashtezakonshme.{' '}
        </li>
        <li>
          Mungesat e mesuesve, pa lejen me shkrim te drejtorit te shkolles ose
          pa deshmi mjekesore, jane mungesa te paarsyeshme.
        </li>
      </ol>
      <h5>Lejohet hyrja pas ores 12-30 vetem per arsye pune.</h5>
      <ol type="1">
        <li>
          Per raste ose situate te vecanta te cilat nuk perfshihen ne
          rregulloren e shkolles, drejoria e shkolles merr vendin e kerkuar.{' '}
        </li>
        <li>
          Ankimi I nxenesve dhe mesuesve paraqitet me shkrim, eshte I
          pershkallezuar, duke kaluar ne instancat me te larta dhe shoqerohet me
          kopjen e pergjigjes se instances paraadhese. Pergjigjet per ankimimet
          jane me shkrim.{' '}
        </li>
        <li>
          Me propozim te drejtorit te shkolles, te kryetarit te bordit, te
          keshillit te prinderve, te qeverise se nxenesve, ose te pakten e
          1/3-es se mesuesve me kohe te plote, nisin procedurat per ndryshimin e
          neneve, ose perfshirjen e neneve te reja ne rregulloren e brendshme te
          shkolles.
        </li>
        <li>
          Mesuesit, nxenesit, dhe prinderit e tyre kane te drejte te informohen
          per rregulloren e brendshme te shkolles.{' '}
        </li>
        <li>Drejtori ka per detyre informimin. </li>
        <li>
          Drejtori ka pergjigjesine per respektin ne shkolle te legjislacionit
          ne fuqi dhe rregullores se brendshme te shkolles
        </li>
        <li>
          Drejtori ka per detyre te mbroje drejtat e te dhenave personale te
          stafit dhe te nxenesve.
        </li>
        <li>
          Te lejoje publikimin e fotografive,videove apo filmimeve vetem duke
          perdorur sfumatura ne fytyrat e femijeve{' '}
        </li>
        <li>
          Te mos lejoje publikimin e materialeve te mbrojtura nga “E drejta e
          autorit” te cilit nuk kane marre pelqimin, bazuar ne parashikimet e
          ligjit nr. 35/2016
        </li>
        <li>
          Hartimi i nje marreveshje standart ndermjet prindit te te miturit apo
          personit qe gezon pergjegjesine prinderore dhe institucionit arsimor,e
          cila perdoret per marrjen e pelqimit me qellimin e publikimit te
          materialeve ku jane prezent femijet.
        </li>
        <li>
          Monitoron pajisjen e nxënësve dhe të stafit me mjetet mbrojtëse, si:
          maskë, xhel dezinfektues për përdorim personal (sipas mundësive).
        </li>
        <li>
          Mban kontakte, përmes komunikimit elektronik, me njësinë e
          vetëqeverisjes vendore, njësinë e mbrojtjes së fëmijëve, strukturat e
          Shëndetit Publik, Policinë e Shtetit dhe organet e tjera ligjzbatuese
          për ecurinë e procesit mësimor.
        </li>
        <li>
          Për cdo mësues apo personel tjetër të shkollës i cili paraqet probleme
          shëndetësore si p.sh. rritje të temperaturës etj. apo ka njoftim se ka
          patur kontakt me dikë të prekur me COVID-19 lajmëron menjëherë Njësinë
          Vendore të Kujdesit Shëndetësor (NJVKSH) dhe strukturën përkatëse në
          këto njësi.
        </li>
        <li>Drejtori pergjigjet per aspektet financiare ne shkolle. </li>
        <li>Mbledhjet e zakonshme zhvillohen pas orarit mesimit. </li>
      </ol>
      <h5>Seksioni Bilingue:</h5>
      <ol type="1">
        <li>
          Seksioni ka rregulla te vecanta pasi është dygjuhësh dhe dy kulturor
          dhe si në familje ashtu edhe studentet duhet t`i njohin e t`i pranojnë
          ato si bazë për një marrëdhënie konstruktive besimi.
        </li>
        <li>
          Mësuesit shqiptarë dhe italiane të seksionit duhet të jenë lehtësues
          në marredheniet mes dy vendeve në një sinergji të sistemeve shkollore
          italiane dhe shqiptare.
        </li>
        <li>
          Mësuesit janë përgjegjës për zgjedhjet dispilinore dhe metodologjike
          të përshtatshme për qëllimin arsimor.
        </li>
        <li>
          Të respektojnë cdo nxënës duke vlerësuar pozitivitetin që shprehet.
        </li>
        <li>
          Ta bëjne klasën përgjegjëse për pjesëmarrje active në të gjitha
          propozimet educative.
        </li>
        <li>
          I bëjnë nxënësit përgjegjës për respektimin e rregullores të
          përbashkët shqiptaro/italiane
        </li>
      </ol>
      <h3>Drejtore: Nevila Zhabjaku</h3>
    </div>
  )
}

export default Rregullorja
