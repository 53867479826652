import React from 'react'
import AboutNav from '../../components/aboutnav'

const Historiku: React.FC = () => {
  return (
    <>
      <AboutNav />
      <HistorikuContent />
    </>
  )
}

const HistorikuContent: React.FC = () => {
  return (
    <div className="text-about">
      <h3>Historia jone</h3>

      <p>
        Shkolla “Ndre Mjeda” lidhet me emrin e nje poeti, autori, teologu dhe
        shkrimtari të shquar që ka lënë gjurmë në shumë shkrime dhe libra. Ajo
        është hapur për herë të parë në vitin 1985. Ajo është një shkollë nëntë
        vjeçare dhe ka filluar veprimtarinë mësimore, edukative në një ndërtesë
        të ngritur në mes të 3 lagjeve të mëdha të qytetit të Shkodrës. Proçesi
        mësimorë filloi ne vitin 1985 me 1082 nxënës, 41 mësues dhe 32 klasa.
        Për të vazhduar në vitin 1987 me 1230 nxënës. Shkolla ishte një ndërtesë
        e bukur por e pamobiluar plotesisht. Për këtë u sollën karrige, banka u
        ndërtuan laboratoret e fizikës, historisë dhe gjeografisë. U vendos
        busti i Ndre Mjedës, u mbodhën pisha për të krijuar kushtet e
        përshtatshme për të zhvilluar proçesin mësimorë. Drejtori i parë i
        shkollës “Ndre Mjeda” u emërua mësuesi me përvojë në drejtim Z. Muhamer
        Vuci, e pastaj zotërinjtë Rasim Kraja, Fatbardh Sokoli, Basri
        Garunja,Ymer Vila e nga zonjat Rumica Dekovi, Vjollca Vuçini dhe Nevile
        Zhabjaku. Mësuesit e kësaj shkolle janë shquar për profesionalizmin,
        korrektësinë dhe seriozitetin duke punuar për ngritjen e tyre
        profesionale, duke marrë pjesë në kualifikime e trajtime të ndryshme,
        gjë që ka bërë që cilësia e mësimdhënies të jetë e një niveli të lartë.
        Që nga viti 1997 shkolla “Ndre Mjeda” është përfshirë rregullisht në
        projekte të ideuara nga Organizma të ndryshme qeveritare dhe
        joqeveritare gjithmonë në ndihmesë të arsimit në rritjen e nivelit të
        mësimdhënies dhe të arritjeve të nxënësve që është edhe themeli I kësaj
        shkolle. Gjatë viteve 1986-1988 shkolla u bë qendër për seminaret e
        drejtorëve të shkollave. Në vitin 1987, u bë binjakzimi me shkollën
        Mësenjtoria e parë në Korcë, ku u realizua shkëmbime eksperiencash. Në
        vitin 1997 shkolla përfshihet në projektin “Step by Step”. Në vitin 2002
        krijohet shkolla verore franceze, nën kujdesin e ambasadës francize. Në
        vitin 2002 aplikohet projekti “Iliria” nën kujdesin e ambasadës
        italiane. Ky project vazhdon akoma edhe sot. Në vitin 2006 u zhvillua
        dhe aplikua projektin “Fëmijët të parët”. Në vitin 2010 u aplikua
        projekti “Bilingue” nën kujdesin e ambasadës Italian, e cila vazhdon
        Akoma sot. Në vitin 2015 I zhvillua projekti “Shkolla Qendër Komunitare”
        e cila vazhdon edhe sot me një përfshierje të gjërë të stafit. Në vitin
        2016 u zhvillua projekti “Gjithëpërfshirja në shkollë”. Në vitin 2017 u
        zhvillua projekti “Stop Bullizmit”. Në vitin 2019 u zhvillua projekti
        “Bëjmë detyrat e shtëpisë”, i cili vazhdon akoma sot si iniciative e
        MAS, që përfshinë bërjen e detyrave dhe kujdesin e nxënësve. Në vitin
        2023 u zhvillua projekti “Arte, Zeje, Sport. Shkolla “Ndre Mjeda” merr
        pjesë në mënyre aktive në aktivitetet e shumta sportive dhe më gjërë.
        Ajo sot ka në përbërjen e saj një staf prej 57 mësuesish, nga këta 37
        mësues janë me mbi 20 vite pune, pra kanë titullin mjeshtër shërbimi
        psiko-sociale, psikologe, punonjëse sociale, officer sigurie. Ky fakt
        vërteton më së miri edhe nivelin e shërbimit që ka kjo shkollë. Gjatë
        viti akademik 2022- 2023 kanë filluar proçesin mësimorë 798 nxënës nga
        ku; 31 nxënës janë egjiptianë dhe 8 nxenes me aftësi ndryshe. Kjo tregon
        për klimën miqësore që shoqëron proçesin mësimorë në këtë shkolle ku çdo
        vit mbarojnë klasën e nëntë 90 deri 100 nxënës, me një kalueshmëri 100%,
        me notë mesatare 8.3 ne provimet PKAB.
      </p>
    </div>
  )
}

export default Historiku
