import React from 'react'
import AboutNav from '../../components/aboutnav'

const BazaLigjore: React.FC = () => {
  return (
    <>
      <AboutNav />
      <h3 className="mt-16">Duke u punuar .....</h3>
    </>
  )
}

export default BazaLigjore
