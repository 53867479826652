import React from 'react'
import NavBar from './components/navbar'
import Footer from './components/footer'
import Copyright from './components/copyright'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './index.css'
// Import Pages
import Home from './pages/home'
import Calendar from './pages/kalendari'
import Provime from './pages/provimet'
import KartaPerformanca from './pages/karta'

// Import Struktura
import Stafi from './pages/stafi'
import QeveriaNxenesve from './pages/qeverianxenesve'
import Biblioteka from './pages/biblioteka'

// Psiko Social
import PlaniVjetor from './pages/psikosocial/planivjetor'
import Analiza1 from './pages/psikosocial/analiza1'
import Analiza2 from './pages/psikosocial/analiza2'
import Analiza3 from './pages/psikosocial/analiza3'

// Rreth nesh
import Historiku from './pages/rreth/historiku'
import BazaLigjore from './pages/rreth/bazaligjore'
import Arritje from './pages/rreth/arritje'
import Rregullorja from './pages/rreth/rregullorja'
import ErrorPage from './pages/errorPage'

function App() {
  const showNavBarAndFooter = window.location.pathname !== '/error'

  return (
    <>
      <Router>
        <NavBar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/njoftime/kalendari" element={<Calendar />} />
          <Route path="/njoftime/provimet" element={<Provime />} />
          <Route
            path="/njoftime/kartaperformances"
            element={<KartaPerformanca />}
          />
          <Route path="/struktura/stafi" element={<Stafi />} />
          <Route
            path="/struktura/qeveria-nxenesve"
            element={<QeveriaNxenesve />}
          />
          <Route
            path="/struktura/biblioteka-digjitale"
            element={<Biblioteka />}
          />
          <Route path="/sherbimi-psikosocial" element={<PlaniVjetor />} />
          <Route
            path="/sherbimi-psiokosocial/analiza1"
            element={<Analiza1 />}
          />
          <Route
            path="/sherbimi-psiokosocial/analiza2"
            element={<Analiza2 />}
          />
          <Route
            path="/sherbimi-psiokosocial/analiza3"
            element={<Analiza3 />}
          />

          <Route path="/rreth/historiku" element={<Historiku />} />
          <Route path="/rreth/baza-ligjore" element={<BazaLigjore />} />
          <Route path="/rreth/arritje" element={<Arritje />} />
          <Route path="/rreth/rregullorja" element={<Rregullorja />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>

        <Footer />

        <Copyright />
      </Router>
    </>
  )
}

export default App
