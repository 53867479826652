import React from 'react'
import '../App.css'
import StrukturaBread from '../components/strukturanav'
import qeveria from '../images/qeverianxenesve.png'

const QeveriaNxenesve: React.FC = () => {
  return (
    <>
      <StrukturaBread />

      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-10 mb-10">
        <h2 className="mb-7 mt-7 text-center">
          Plan pune i Qeverise se Nxenesve
        </h2>
        <img src={qeveria} alt="" />
      </div>
    </>
  )
}

export default QeveriaNxenesve
