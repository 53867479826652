import React from 'react'
import stafi from '../images/STAFI.jpg'
import drejtoresha from '../images/dr-Nevila-Zhabjaku.jpg'
import vizionmision from '../images/mision vizion.png'

const Home: React.FC = () => {
  return (
    <>
      <FirstSection />
      <SecondSection />
      <VisionMissionSection />
    </>
  )
}

const FirstSection: React.FC = () => {
  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-16 mb-16">
      <div className="img-container ">
        <img src={stafi} alt="Image" />
        <div className="caption">Miresevini</div>
      </div>
    </div>
  )
}

const SecondSection: React.FC = () => {
  return (
    <section className="dr-section mt-16 mb-16">
      <img src={drejtoresha} alt="Drejtoresha Nevila Zhabjaku" />
      <div className="text-content">
        <h3>Fjala e drejtoreshes</h3>
        <p>
          Të dashur nxënës, mësues dhe prindër! Urime për fillimin e vitit të ri
          shkollor 2022-2023! Po punojmë me tërë forcën dhe dashurinë për të
          siguruar një rrugëtim të sigurt dhe cilësi të lartë në shkollë. Ju
          premtoj një shkollë që ju përgatit për shoqërinë e dijes dhe ju bën të
          barabartë me bashkëmoshatarët tuaj. Mësuesit janë shkolla dhe keni rol
          vendimtar në arritjen e objektivave reformuese. Falënderoj prindërit
          për përfshirjen dhe ftesën për të qenë aktivë në jetën e shkollës.
          Kemi renditur shkollën tonë në vend të parë dhe karta e performancës
          do të na ndihmojë të monitorojmë punën tonë. Këtë vit shkollor,
          synojmë të ndalim dhunën në shkollë dhe të mbrojmë të drejtat e
          fëmijëve për të jetuar pa dhunë. Mësuesit, nisni ditën e parë me urime
          të bukura për nxënësit tuaj në çdo orë mësimi. Urime të mira dhe
          suksese në rrugëtimin e vitit shkollor!
        </p>
      </div>
    </section>
  )
}

const VisionMissionSection: React.FC = () => {
  return (
    <section className="dr-section mt-16 mb-16">
      <div className="text-content">
        <div className="vision">
          <h3>Vizioni</h3>
          <p>
            Të jemi shkollë, model në rand rajonal në zhvillimin akademik,
            teknologjik dhe social-kulturorë të nxënësve për të krijuar
            qytetarin e formuar globalisht.
          </p>
        </div>
        <div className="mision">
          <h3>Misioni</h3>
          <p>
            Drejtë një shkolle miqësore të sigurtë, gjithëpërfshirëse duke
            nxitur edukimin dhe arritjen e rezultateve sa me te mira.
          </p>
        </div>
      </div>
      <img src={vizionmision} alt="" />
    </section>
  )
}

export default Home
