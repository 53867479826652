const pages = [
  { name: 'Stafi i Shkolles', href: '/struktura/stafi', current: false },
  {
    name: 'Qeveria e nxenesve',
    href: '/struktura/qeveria-nxenesve',
    current: false,
  },
  {
    name: 'Biblioteka Digjitale',
    href: '/struktura/biblioteka-digjitale',
    current: false,
  },
]

export default function StrukturaBread() {
  return (
    <nav className="flex flex-col sm:flex-row mt-8" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              {index !== 0 && (
                <svg
                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
              <a
                href={page.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 bread-anchor"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
