import React from 'react'
import PsiokosocialNav from '../../components/psikosocialnav'

const PlaniVjetor: React.FC = () => {
  return (
    <>
      <PsiokosocialNav />
      <h3 className="mt-16">Duke u punuar .....</h3>
    </>
  )
}

export default PlaniVjetor
