import React from 'react'
import calendar from '../images/Kalendari.jpg'
import NjoftimeBread from '../components/njoftimenav'

const Calendar: React.FC = () => {
  return (
    <>
      <NjoftimeBread />
      <CalendarImg />
    </>
  )
}

const CalendarImg: React.FC = () => {
  return (
    <div className="calendar-container">
      <img src={calendar} alt="Kalendari Shkollor" className="calendar-photo" />
    </div>
  )
}

export default Calendar
