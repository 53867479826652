import React from 'react'

const Copyright: React.FC = () => {
  return (
    <div className="copyright no-copy">
      <p>© 2023 - Te Gjitha Te Drejtat e Rezervuara nga Donatori LUFAPRINT</p>
    </div>
  )
}

export default Copyright
