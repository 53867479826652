import React from 'react'

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 no-footer">
      <ul className="social-contact">
        <h3>Punuar nga</h3>
        <li>Remila Kalo</li>
        <li>Enkelejda Lacej</li>
        <li>Klodiana Kavaja</li>
        <li>Erald Dibra</li>
      </ul>
      <ul className="social-contact">
        <h3>Kontakt</h3>
        <li>
          <i className="bx bx-map-pin"></i>Rruga Kongresi i Lushnjes, Shkoder
        </li>
        <li>
          <a href="mailto:nevilazhabjaku@yahoo.com">
            <i className="bx bx-envelope"></i> nevilazhabjaku@yahoo.com
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/people/Shkolla-9-vjecare-Ndre-Mjeda-Shkoder-Faqja-Zyrtare/100063635135724/"
            target="_blank"
          >
            <i className="bx bxl-facebook"></i>Shkolla 9-vjecare ''Ndre Mjeda''
            Shkoder-Faqja Zyrtare
          </a>
        </li>
        <li>
          <i className="bx bx-calendar-week"></i> 8:00 - 17:00 nga E Hena ne te
          Premte
        </li>
      </ul>

      <iframe
        className="google-map"
        src="https://www.google.com/maps/embed?pb=!4v1685287584559!6m8!1m7!1sWY5jOMygwjfiqwHeL4z_vA!2m2!1d42.0627803125791!2d19.50585375692884!3f296.9840720174023!4f-2.1111843720735237!5f0.7820865974627469"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </footer>
  )
}

export default Footer
